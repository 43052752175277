import React from "react";
import github from '../Assets/github.png';
import logo from "../Assets/oddjobslogo.png";
const ProjectCard = (props) => {
  
  return (
    <div className="ProjectCard">
      <img src={props.project.img}></img>
        <h2>{props.project.title}</h2>
        <p>{props.project.desc}</p>
        <p>{props.project.topics.map((topic, index) => {
            return (<span key={index}>{topic.toUpperCase()+ '•'}  </span>)
        })}<span>{props.project.type.toUpperCase()}</span></p>
        <div className="projectcard-externals">
          {props.project.deploy != '' ? <a href={props.project.deploy}>Deploy<img src={logo} alt="the oddjobs logo, a diamond of four squares withe the rightmost being green and the others white"></img></a>
          : <></>}
          {props.project.github != '' ? <a href={props.project.github}>Github<img src={github} alt="the github logo, a cat with eight legs"></img></a>
          : <></>}
        </div>
    </div>
  );
};

export default ProjectCard;