import axios from "axios";

const api= axios.create({
    //baseURL: 'https://portfoliodatabase.herokuapp.com/',
    withCredentials: true,
    baseURL: 'http://localhost:27017/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json'
    },
})

const apiDriver = {
    //this returns all blog posts
    getAllBlogPosts: (params) => api({
        'method': 'GET',
        'url': '/blog',
        'params': {
            ...params
        },
    }),
    createBlogPost:(post) => api({
        'method': 'POST',
        'url': '/blog',
        'data': post,
    }),
    updateBlogPost:(post) => api({
        'method': 'PUT',
        'url': `/blog/${post._id}`,
        'data': post,
        
    }),
    deleteBlogPost:(post) => api({
        'method': 'DELETE',
        'url': `/blog/${post._id}`,
    }),
    login: (data) => api({
        'method': 'POST',
        'url': '/admin/login',
        'data': {
            username: data.username,
            password: data.password
        }
    })
}

export default apiDriver;