import React from "react";
import ProjectCard from "./ProjectCard";
import "../Styles/Projects.css";
import github from '../Assets/github.png'
import james from "../Assets/james.png";
import animal from "../Assets/animal.jpg";
import map from "../Assets/map.jpg";
import blog from "../Assets/blog.jpg";
import cards from "../Assets/cards.jpg";
import galenpic from "../Assets/github.png";
import python from "../Assets/267_Python-512.png";
import logo from "../Assets/oddjobslogo.png";
import endever from '../Assets/endever.png';

const frontendprojects = [
  {
    type: "frontend",
    img: james,
    title: "PB 'n James Games",
    desc: "A Commisioned Portfolio to Showcase A Game Developer's Work",
    topics: ["react", "javascript", "css", "html"],
    deploy: "https://www.pbnjames.games/",
    github: "",
  },
  {
    type: "frontend",
    img: galenpic,
    title: "digitalfences.github.io",
    desc: "A Collection of small web projects I've completed from earlier in my js journey",
    topics: ["react", "javascript", "css", "html", "components"],
    deploy: "https://digitalfences.github.io/",
    github: "https://github.com/digitalfences/digitalfences.github.io",
  },/*
  {
    type: "frontend",
    img: logo,
    title: "Odd Jobs Development",
    desc: "A Home Page For A Web Development Firm",
    topics: ["react", "javascript", "css", "html"],
    deploy: "",
    github: "",
  },*/
];

const databaseapiprojects = [
  {
    type: "backend",
    img: python,
    title: "NomNoms",
    desc: "An incomplete application for kitchen ingredient management, excited to show more information about this as it undergoes development",
    topics: ["react", "javascript", "css", "html"],
    deploy: "https://upbeat-pare-297823.netlify.app/",
    github: "",
  },
  {
    type: "backend",
    img: map,
    title: "US States and Cities API",
    desc: "An Api of us states and cities",
    topics: ["react", "javascript", "css", "html", "components"],
    deploy: "",
    github: "https://github.com/digitalfences/USStatesCitiesAPI",
  },
  {
    type: "backend",
    img: cards,
    title: "Flash Cards",
    desc: "A Flash Cards CLI application written in python",
    topics: ["react", "javascript", "css", "html"],
    deploy: "",
    github: "https://github.com/digitalfences/9GACommandLine-Python",
  },
  {
    type: "backend",
    img: endever,
    title: "Endever",
    desc: "A Tinder Clone for software developers - currently not being maintained, but github oauth is implemented in the back-end allowing users to load their profiles onto tinder cards",
    topics: ["react", "javascript", "css", "html", "components"],
    deploy: "https://agitated-panini-b410aa.netlify.app/",
    github: "https://github.com/digitalfences/EndeverBackEnd",
  },
  /*{
    type: "backend",
    img: blog,
    title: "Personal Blog Site",
    desc: "A blog site with built in word processor and backend database",
    topics: ["react", "javascript", "css", "html"],
    deploy: "",
    github: "",
  },*/
];

const datascienceprojects = [
  {
    type: "data science",
    img: animal,
    title: "Animal Neural Network",
    desc:
      "A simple network for predicting what type of animal a creature is according to the sound it makes",
    topics: [
      "machine learning",
      "python",
      "jupyter notebook",
      "neural network",
    ],
    deploy: "",
    github: "https://github.com/digitalfences/djangomachinelearningsandbox",
  },
];

const Projects = () => {
  return (
    <div className="Projects">
      <a href="/">Back To Home</a>
      <h2>Projects and Failures</h2>
      <h2>Front-End Design</h2>
      <div className="projects-grid">
        {frontendprojects.map((project) => {
          return (
            <ProjectCard key={project.title} project={project}></ProjectCard>
          );
        })}
      </div>

      <h2>Databases And APIs</h2>
      <div className="projects-grid">
        {databaseapiprojects.map((project) => {
          return (
            <ProjectCard key={project.title} project={project}></ProjectCard>
          );
        })}
      </div>

      <h2>Data Science</h2>
      <div className="projects-grid">
        {datascienceprojects.map((project) => {
          return (
            <ProjectCard key={project.title} project={project}></ProjectCard>
          );
        })}
      </div>

      <div className="footer">
        Copyright <a href="https://www.galen.codes">Galen Emanuel</a> 2021
      </div>
    </div>
  );
};

export default Projects;
