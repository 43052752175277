import React from "react";
import galenpic from "../Assets/warmgalenpic.jpg";
import resume from "../Assets/Galen-Emanuel Resume FSE (3).pdf";
import "../Styles/HomeAlt.css";

const Home = () => {
  return (
    <div className="Home">
      <div className="intro"><h2>
          <span className="outlined">Hi, I'm Galen</span>
        </h2>
        <h2>JavaScript Developer and Software Engineer</h2>
        <img
          className="intro-pic"
          src={galenpic}
          alt="My smiling and trustworthy and also hireable face."
        ></img>
        <h2>MERN Expert</h2>
        <h2>Data Enthusiast </h2>
        <h2>Empathetic Designer</h2>
        
        <div className="intro-socials">
          <span className="style-codeblock">
            <a href="">LinkedIn</a>
          </span>
          <span className="style-codeblock">
            <a href="">Twitter</a>
          </span>
          <span className="style-codeblock">
            <a href="">Github</a>
          </span>
        </div>
      </div>
      <div className="bio">
        <p>Available for Full-time or Freelance</p>
        <p>Oakland | Remote</p>
        <div className='bio-email'><a href="mailto: galen.emanuel.fencing@gmail.com">Reach Me By Email!</a></div>
        <div className='bio-projects'><a href='/projects/'>Check Out My Projects!</a></div>
        <div className='bio-resume'><a href={resume} download><span className="illuminated">Download Resume (PDF){" "}</span></a></div>
      </div>

      <div className="footer">
        Copyright <a href="https://www.galen.codes">Galen Emanuel</a> 2021
      </div>
    </div>
  );
};

export default Home;
