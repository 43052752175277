import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Nav from "./Components/Nav";
import Home from "./Components/HomeAlt";
import Projects from './Components/Projects'
import BlogList from "./Components/Bloglist";
import AdminHome from './Components/admin/AdminHome';
import Helmet from 'react-helmet';
import galen from './Assets/warmgalenpic.jpg';
import './App.css';
/*
<Helmet>
        <title>{seo.title}</title>
        <meta property="og:title" content={seo.title} />
        <meta property="og:type" content={'website'} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:description" content={seo.description} />
      </Helmet>
       const seo = {
    title: "Galen Codes",
    image: galen,
    url: 'https://www.galen.codes',
    description: 'Galen Emanuel - A Full Stack Developer'
  }
      */
function App() {
 
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/projects/">
            <Projects></Projects>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
